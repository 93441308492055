import React from 'react';
import { simpleInertiaApp } from '@lifespikes/js-beam';
import { createRoot } from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FacebookProvider } from 'react-facebook';
import {
  VITE_FACEBOOK_APP_DEFAULT_GRAPH_VERSION,
  VITE_FACEBOOK_APP_ID,
  VITE_FULLSTORY_ORG,
  VITE_SENTRY_DSN,
} from '@php-beam/packages/frontend/src/config';
import { PusherNotificationClientProvider } from '@php-beam/packages/frontend/src/contexts/PusherContextProvider';
import { setupLaravelEcho } from '@php-beam/packages/frontend/src/support/utils';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import Progress from '@php-beam/packages/frontend/src/support/progress';
import { KeyBindProvider } from 'react-keybinds';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-credit-cards/es/styles-compiled.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import './styles/index.css';
import JanusThemeContextProvider from '@php-beam/packages/frontend/src/contexts/JanusThemeContextProvider';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
// @ts-ignore
import dayjsBusinessDays from 'dayjs-business-days';

if (VITE_FULLSTORY_ORG) {
  FullStory.init({ orgId: VITE_FULLSTORY_ORG });
}

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

if (typeof window !== 'undefined') {
  window.global = window;
}

Progress.init({
  showSpinner: true,
});

setupLaravelEcho();

dayjs.extend(dayjsBusinessDays);
dayjs.extend(advancedFormat);

const queryClient = new QueryClient();
void simpleInertiaApp({
  pages: import.meta.glob('./pages/**/*.{tsx,jsx}'),
  setup({ el, App, props: setupProps }) {
    const root = createRoot(el);
    root.render(
      <PusherNotificationClientProvider>
        <KeyBindProvider>
          <QueryClientProvider client={queryClient}>
            <App {...setupProps}>
              {({ Component, props, key }) => (
                <JanusThemeContextProvider>
                  <FacebookProvider
                    appId={VITE_FACEBOOK_APP_ID}
                    version={VITE_FACEBOOK_APP_DEFAULT_GRAPH_VERSION}
                  >
                    <Component {...props} key={key} />
                  </FacebookProvider>
                </JanusThemeContextProvider>
              )}
            </App>
            <ReactQueryDevtools initialIsOpen={false} position={'top-left'} />
          </QueryClientProvider>
        </KeyBindProvider>
      </PusherNotificationClientProvider>,
    );
  },
});
